<template>
  <b-row>
    <b-col lg="12">
      <section class="p-4 p-md-5" style="background-image: url(https://mdbcdn.b-cdn.net/img/Photos/Others/background3.webp);height: 100%;position: fixed;width: 100%;">
        <div class="row d-flex justify-content-center">
          <div class="col-md-10 col-lg-8 col-xl-5">
            <div class="card rounded-3">
              <div class="card-body p-4">
                <div class="text-center mb-4">
                  <h3>Rezervare online</h3>
                </div>
                <div class="mb-4 col-sm-12">
                  <h6><i class="fa-solid fa-user-doctor"></i> Doctor: {{ theUser }}</h6>
                  <h6><i class="fa-solid fa-briefcase"></i> Servicii: {{ theServices }}</h6>
                  <h6><i class="fa-solid fa-calendar-days"></i> Data: {{ theDate }}</h6>
                  <h6><i class="fa-solid fa-clock"></i> Ora: {{ theHour }}</h6>
                </div>
                  <b-form-group class="col-sm-12 m-0 prices">
                    <span style="border-top: 1px solid blue; padding-top:8px;">Total de plata: {{ selectedServicePriceTotal }} {{ currency }}</span>
                  </b-form-group>
                  <b-form-group class="col-sm-12">
                    <label>Alege una din metodele de plata:</label><br>
                    <b-form-radio class="text-uppercase" inline v-model="user.payment" v-for="(val, id) in availablePayments" :value="val" :key="id">{{ val }}</b-form-radio>
                  </b-form-group>

                  <b-form-group class="col-sm-12" v-show="user.payment === 'cash'">
                    <b-button type="submit" variant="primary" class="col-sm-12" @click="makeBooking(user)">{{ $t('booking.pay_cash') }}</b-button>
                  </b-form-group>
                  <b-form-group class="col-sm-12" id="paypalPayment" v-if="user.payment === 'paypal'">
                  </b-form-group>
                  <b-form-group class="col-sm-12" id="stripePayment" v-if="user.payment === 'stripe'">
                    <stripe-element-card
                      ref="elementRef1"
                      :pk="stripeUserId"
                      @token="tokenCreated"
                    />
                    <b-button type="submit" variant="primary" class="col-sm-12" @click="payWithStripe" v-if="!paymentInProgress">{{ $t('booking.pay_stripe') }}</b-button>
                  </b-form-group>
                  <b-form-group class="col-sm-12"  id="revolutPayment" v-if="user.payment === 'revolut'">
                    <b-button type="submit" variant="primary" class="col-sm-12" @click="createRevolutApiOrder">{{ $t('booking.pay_revolut') }}</b-button>
                  </b-form-group>
                  <div class="col-sm-12 pt-0" v-if="paymentError" style="color: red; font-size: 13px;">{{ paymentError }}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </b-col>
    <b-col lg="12">
      <nav aria-label="breadcrumb" v-if="this.selectedServiceName !== ''">
        <ol class="breadcrumb bg-primary mb-0">
          <li><a href="#" class="text-white">{{ selectedServiceName }} </a></li>
          <li v-if="this.selectedEmployeeName" class="ml-1"><a href="#" class="text-white">- {{ selectedEmployeeName }}</a></li>
          <li v-if="this.selectedDateName" class="ml-1">- {{ selectedDateName }}</li>
        </ol>
      </nav>
    </b-col>
    <b-col lg="12">
      <div class="isLoadingEvent" v-if="isLoadingEvent"><div class="loader"></div></div>
    </b-col>
    <div class="booking_complete" v-if="bookingComplete">
      <div>Plata a fost facuta cu succes! <br><br>Vei primi un mesaj de reamintire<br>
      </div>
    </div>

    <div class="booking_available" v-if="!isAvailable">
      <div>
        {{ $t('pay.booking_not_available') }}
      </div>
    </div>
  </b-row>
</template>
<script>
import Axios from 'axios'
import { API } from '@/config/api.config'
import { core } from '@/config/pluginInit'
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'CalendarBook',
  components: {
    StripeElementCard
  },
  props: {
    thecompanytoken: String,
    theuser: Object
  },
  data () {
    return {
      theUser: '',
      theServices: '',
      theDate: '',
      theHour: '',
      device: Capacitor.getPlatform() || 'web',
      paymentError: false,
      paymentInProgress: false,
      discountValoare: '',
      discountText: '',
      discountError: false,
      isDiscount: false,
      isDiscountOpened: false,
      isAvailable: true,
      isLoadingEvent: false,
      stripeUserId: '',
      token: null,
      bookingComplete: false,
      currency: '',
      paypalPayment: '',
      availablePayments: [],
      errorPersists: false,
      isConnected: false,
      name: '',
      email: '',
      personalID: '',
      picture: '',
      FB: undefined,
      socialLogin: true,
      apiToken: localStorage.getItem('api_token') || '',
      customerApiToken: localStorage.getItem('customer_api_token') || '',
      params: {
        client_id: '469947475180-13204kpb0ljgsbmavfeo4doufpeq8ur9.apps.googleusercontent.com'
      },
      user: {
        fname: '',
        lname: '',
        phone: '',
        email: '',
        payment: '',
        picture: '',
        notification_sms: '',
        notification_email: '',
        newsletter: '',
        on_hold: ''
      },
      bookingToken: this.$route.params.id || '',
      companyToken: '',
      employeeToken: this.$route.params.employee || '',
      services: [],
      employees: [],
      availability: [],
      theDates: {
        typeOfDay: '',
        theDate: '',
        values: {}
      },
      nextWeek: true,
      previousWeek: false,
      currentWeek: '',
      search: '',
      searche: '',
      selectedService: [],
      selectedServiceId: [],
      selectedServiceName: '',
      selectedServicePrice: 0,
      selectedServicePriceDiscount: 0,
      selectedServicePriceTotal: 0,
      selectedServiceChecked: [],
      selectedEmployeeId: '',
      selectedEmployeeName: '',
      selectedDate: '',
      selectedDateName: '',
      selectedDay: '',
      selectedDayComplete: '',
      selectedTimeStart: '',
      selectedTimeEnd: '',
      dateConfig: {
        inline: false,
        minDate: 'today',
        dateFormat: 'Y-m-d',
        defaultDate: 'today',
        locale: {
          firstDayOfWeek: 1
        }
      },
      revolutUserId: '',
      revolutInstance: '',
      descriptionEvent: '',
      selectedEmployeeByDefault: []
    }
  },
  methods: {
    tokenCreated (token) {
      console.log('aicicici')
      if (token.id) {
        this.sendPaymentToStripe(token)
      }
    },
    payWithStripe () {
      if (this.validateSubmission()) {
        this.$refs.elementRef1.submit()
      }
    },
    sendPaymentToStripe (token) {
      this.paymentError = false
      this.paymentInProgress = true
      const dataPost = {
        token: token,
        currency: this.currency,
        amount: this.selectedServicePriceTotal,
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_STRIPE_ORDER, dataPost)
        .then((response) => {
          this.paymentInProgress = false
          if (response.data.status === 'success') {
            this.makeBooking(this.user, response.data.response)
          }
        })
        .catch((error) => {
          console.log(error.response.status)
          this.paymentInProgress = false
          if (error.response && error.response.status === 500) {
            this.paymentError = error.response.data.message
          } else if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    sendPaymentToStripeFromApp (token) {
      this.paymentError = false
      this.paymentInProgress = true
      const dataPost = {
        token: token,
        currency: this.currency,
        amount: this.selectedServicePriceTotal,
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_STRIPE_ORDER, dataPost)
        .then((response) => {
          this.paymentInProgress = false
          if (response.data.status === 'success') {
            this.makeBookingFromApp(this.user, response.data.response)
          }
        })
        .catch((error) => {
          this.paymentInProgress = false
          console.log(error.response.status)
          if (error.response && error.response.status === 500) {
            this.paymentError = error.response.data.message
          } else if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    newBooking () {
      this.$router.go()
    },
    initializeRevolut () {
      const script1 = document.createElement('script')
      script1.text = '!function(e,o,t){e[t]=function(n,r){var c={sandbox:"https://sandbox-merchant.revolut.com/embed.js",prod:"https://merchant.revolut.com/embed.js",dev:"https://merchant.revolut.codes/embed.js"},d=o.createElement("script");d.id="revolut-checkout",d.src=c[r]||c.prod,d.async=!0,o.head.appendChild(d);var s={then:function(r,c){d.onload=function(){r(e[t](n))},d.onerror=function(){o.head.removeChild(d),c&&c(new Error(t+" is failed to load"))}}};return"function"==typeof Promise?Promise.resolve(s):s}}(window,document,"RevolutCheckout");'
      document.head.appendChild(script1)
    },
    createRevolutApiOrder () {
      if (this.validateSubmission()) {
        const dataPost = {
          amount: this.selectedServicePriceTotal * 100,
          currency: this.currency,
          userId: this.revolutUserId,
          instanceType: this.revolutInstance
        }

        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        Axios.post(API.API_SET_REVOLUT_ORDER, dataPost)
          .then((response) => {
            if (response.data.status === 'success') {
              if (response.data.response.public_id) {
                this.setRevolutLoaded(response.data.response.public_id, this.revolutInstance, response.data.response)
              } else {
                core.showSnackbar('error', this.$t('booking.check_api_key'))
              }
            }
          })
          .catch((error) => {
            core.showSnackbar('error', error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    setRevolutLoaded (apiKey, instanceType, theResponse) {
      window.RevolutCheckout(apiKey, instanceType).then(function (instance) {
        instance.payWithPopup({
          locale: 'en',
          onSuccess () {
            this.makeBooking(this.user, theResponse)
          },
          onError (message) {
            core.showSnackbar('error', message)
          }
        })
      })
    },
    setRevolutLoadedFromApp (apiKey, instanceType, theResponse) {
      window.RevolutCheckout(apiKey, instanceType).then(function (instance) {
        instance.payWithPopup({
          locale: 'en',
          onSuccess () {
            this.makeBookingFromApp(this.user, theResponse)
          },
          onError (message) {
            core.showSnackbar('error', message)
          }
        })
      })
    },
    initializePaypal (userId) {
      const script = document.createElement('script')
      script.src = 'https://www.paypal.com/sdk/js?client-id=' + userId + '&currency=' + this.currency
      script.addEventListener('load', this.setPaypalLoaded)
      document.head.appendChild(script)
    },
    setPaypalLoaded () {
      const paymentDescription = this.selectedServiceName + ' - ' + this.selectedDateName
      const totalToPay = this.selectedServicePriceTotal
      const clientInfo = this.user
      const currentInstance = this
      window.paypal.Buttons({
        onClick: function (data, actions) {
          if (!currentInstance.validateSubmission()) {
            return actions.reject()
          }
        },
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                description: paymentDescription,
                amount: {
                  value: totalToPay
                }
              }
            ]
          })
        },
        onApprove: function (data, actions) {
          return actions.order.capture().then(function (details) {
            if (details.status === 'COMPLETED') {
              // submit the forms with the rest of the infos
              if (currentInstance.thecompanytoken) {
                currentInstance.makeBookingFromApp(clientInfo, details)
              } else {
                currentInstance.makeBooking(clientInfo, details)
              }
            } else {
              core.showSnackbar('error', this.$t('booking.error_payment'))
            }
          })
        },
        onError: (error) => {
          core.showSnackbar('error', error)
        }
      }).render('#paypalPayment')
    },
    getUserData () {
      this.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
        user => {
          this.personalID = user.id
          this.user.email = user.email
          const theUserName = user.name.split(' ')
          this.user.fname = theUserName[0]
          this.user.lname = theUserName[1]
          this.user.picture = user.picture.data.url
          this.socialLogin = false
        }
      )
    },
    setDate (item1, item) {
      this.selectedDate = item.time
      this.selectedDateName = item1.typeOfDay + ', ' + item1.theDate + ' Time: ' + item.start + '-' + item.end
      this.selectedDay = item1.theDateSend
      this.selectedTimeStart = item.start
      this.selectedTimeEnd = item.end
      this.$refs.theDetails.$el.children[0].click()
    },
    getBookingDetails () {
      const dataPost = {
        token: this.bookingToken
      }
      Axios.post(API.API_GET_BOOKING_DETAILS, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            // get payments
            this.selectedServicePriceTotal = response.data.booking.amount
            this.currency = response.data.booking.currency
            this.companyToken = response.data.booking.company
            this.getAvailablePayments(this.companyToken)
            this.theUser = response.data.booking.employee.fname + ' ' + response.data.booking.employee.lname
            const theServicesObj = response.data.booking.service
            theServicesObj.forEach((value) => {
              this.theServices += ', ' + value.name
            })
            this.theServices = this.theServices.substring(2)
            const theDateObj = response.data.booking.data.split(' ')
            this.theDate = theDateObj[0]
            this.theHour = theDateObj[1]
            this.getCompanyLanguage()
          } else {
            this.$router.push('/auth/login')
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    },
    checkEmployeeToken () {
      const token = {
        company: this.$route.params.id,
        employee: this.employeeToken
      }
      Axios.post(API.API_CHECK_EMPLOYEE_TOKEN, token)
        .then((response) => {
          if (response.data.status === 'success') {
            this.selectedEmployeeByDefault = response.data.theEmployee
          } else if (response.data.status === 'fail') {
            this.$router.push('/pages/error/404')
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getCompanyLanguage () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.post(API.API_GET_COMPANY_LANGUAGE, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.$i18n.locale = response.data.data.language
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getAvailablePayments () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.post(API.API_GET_AVAILABLE_PAYMENTS, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.data.available_payments) {
              this.availablePayments = response.data.data.available_payments
            }
            if (response.data.data.payment_test) {
              this.testPayment = response.data.data.payment_test
            }
            if (response.data.data.payment_paypal) {
              const paymentPaypal = JSON.parse(response.data.data.payment_paypal)
              if (paymentPaypal.active === true) {
                const paypalUserId = (this.testPayment === '1') ? paymentPaypal.address_test : paymentPaypal.address
                this.initializePaypal(paypalUserId)
              }
            }
            if (response.data.data.payment_revolut) {
              const paymentRevolut = JSON.parse(response.data.data.payment_revolut)
              if (paymentRevolut.active === true) {
                this.revolutUserId = (this.testPayment === '1') ? paymentRevolut.api_sandbox : paymentRevolut.api
                this.revolutInstance = (this.testPayment === '1') ? 'sandbox' : 'prod'
                this.initializeRevolut()
              }
            }
            if (response.data.data.payment_stripe) {
              const paymentStripe = JSON.parse(response.data.data.payment_stripe)
              if (paymentStripe.active === true) {
                this.stripeUserId = (this.testPayment === '1') ? paymentStripe.test_publishable : paymentStripe.live_publishable
              }
            }
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    makeBooking (client, transaction = null) {
      if (this.validateSubmission()) {
        this.isLoadingEvent = true
        const dataPost = {
          token: this.bookingToken,
          company: this.companyToken,
          employee: this.selectedEmployeeId,
          service: this.selectedServiceId,
          selectedDay: this.selectedDay,
          selectedTimeStart: this.selectedTimeStart,
          selectedTimeEnd: this.selectedTimeEnd,
          client: client,
          currency: this.currency,
          amount: this.selectedServicePriceTotal,
          subtotal: this.selectedServicePrice,
          discount: this.selectedServicePriceDiscount,
          voucher: this.discountValoare,
          transaction: transaction,
          description: this.descriptionEvent
        }
        Axios.post(API.API_SET_BOOKING_PAYED, dataPost)
          .then((response) => {
            if (response.data.status === 'success') {
              this.isLoadingEvent = false
              this.bookingComplete = true
              this.paymentInProgress = false
            }
          })
          .catch((error) => {
            core.showSnackbar('error', error.response)
          })
      }
    },
    makeBookingFromApp (client, transaction = null) {
      this.user.notification_sms = 1
      this.user.notification_email = 1
      this.user.on_hold = 0
      const userName = this.user.name.split(' ')
      this.user.fname = userName[0]
      this.user.lname = userName[1]
      const dataPost = {
        company: this.companyToken,
        employee: this.selectedEmployeeId,
        service: this.selectedServiceId,
        selectedDay: this.selectedDay,
        selectedTimeStart: this.selectedTimeStart,
        selectedTimeEnd: this.selectedTimeEnd,
        client: client,
        currency: this.currency,
        amount: this.selectedServicePriceTotal,
        subtotal: this.selectedServicePrice,
        discount: this.selectedServicePriceDiscount,
        voucher: this.discountValoare,
        transaction: transaction,
        description: this.descriptionEvent
      }
      Axios.post(API.API_ADD_BOOKING, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('booking.booking_successfull'))
            setTimeout(() => {
              location.reload()
            }, 2000)
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    },
    validateSubmission () {
      this.errorPersists = false
      if (this.errorPersists) {
        core.showSnackbar('error', this.errorPersists)
        return false
      }

      return true
    }
  },
  mounted () {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css'
    document.head.appendChild(link)
    this.isLoadingEvent = true
    this.bookingToken = this.$route.params.id
    this.getBookingDetails()

    this.isLoadingEvent = false
  }
}
</script>
<style>
.fa-facebook,
.fa-google {
  position: relative;
}
.FacebookButton{
  position: absolute;
  width: 50px !important;
  overflow: hidden;
  height: 50px;
  top: 0;
  left: calc( 50% - 25px );
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0;
}
.FacebookButton button{
  height: 50px;
}
.GoogleButton{
  position: absolute;
  width: 50px !important;
  overflow: hidden;
  height: 50px;
  top: 0;
  left: calc( 50% - 25px );
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0;
}
.booking_complete{
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000000eb;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999;
}
.booking_complete div{
  position: absolute;
  top: 40%;
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 20px;
}
.image_of_service,
.image_of_employee{
  overflow: hidden;
}
.image_of_service img,
.image_of_employee img{
  height: 120px;
  width: 120px !important;
  object-fit: cover;
}
.isSelected .card{
  background: #ffe173 !important;
}
.dateButton{
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  padding-bottom: 5px;
  background: #e9ecef;
  color: #646464;
  border-radius: 5px;
}
.dateButton:hover{
  background: #0c88b4;
}
.disabled {
  pointer-events:none;
  opacity:0.6;
}
@media only screen and (max-width: 767px) {
  .fontSize{
    font-size: 12px;
  }
  .fontSize1{
    font-size: 14px;
  }

}
.b-card-details{
  font-size: 12px;
  line-height: 13px;
}
.isLoadingEvent{
  background: #000000e6;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #19bdb4;
  width: 120px !important;
  height: 120px !important;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  top: 15% !important;
  bottom: auto !important;
}
.booking_available{
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000000eb;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999;
}
.booking_available div{
  position: absolute;
  top: 40%;
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 20px;
}
.topStickyHeader{
  top: 0;
  position: sticky;
  background: white;
  z-index: 999;
}
.suppress{
  display: none !important;
}
.extendible{
  position: absolute;
  right: 10px;
  top: 7px;
}
.fa-minus-square{
  display: none;
}
.suppress.isSearched{
  display: block !important;
}
.discount{
  font-size: 12px;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
}
.discountValue{
  font-size: 12px;
  display: block;
  cursor: pointer;
}
.discountValue span{
  color: #ff9900;
}
.discountValue span.underline{
  text-decoration: underline;
  color: red;
  margin-left: 10px;
}
.isDiscount{
  font-size: 14px !important;
  color: black;
  border: 1px solid grey;
}
.discountError{
  font-size:12px;
  color: red;
}
.prices{
  color: #005aff;
  font-size: 17px;
  font-weight: bold;
}
.description {
  line-height: 20px !important;
}
</style>
